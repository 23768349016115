
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { MenuIcon, XIcon, UserIcon } from '@heroicons/vue/outline'
import {computed, defineComponent, ref, onMounted} from "vue"
import Navigation from '../types/Navigation'
import {useStore} from "vuex";
import userActions from "@/store/user/user-actions";
import {sendPostMessage, siteConfig, base64ToBase64Url, decodeJWT, RSAEncrypt} from "@/composables/helpers";
import {SiteConfig} from "@/store/types/types";
import LanguageDropdown from "@/components/LanguageDropdown.vue";
export default defineComponent({
    name: 'MainHeader',
    components: {
        LanguageDropdown,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        MenuIcon,
        XIcon,
        UserIcon,
    },
    setup() {
        const config: SiteConfig = siteConfig();

        let navigation = ref<Navigation[]>([
            { key: 'home', path: '/euro/home'},
            { key: 'sports', path: '/euro/sport/soccer'},
            { key: 'live_betting', path: '/euro/live'},
        ])

        const store = useStore();
        let currentNavItem = computed(() => store.state.application.path)
        const isLogged = computed(() => store.state.user.isLogged);

        const registerEnabled = siteConfig().registerEnabled
        const loginEnabled = siteConfig().loginEnabled
        const ssoEnabled = siteConfig().ssoEnabled

        if(!isLogged.value) {
            navigation.value = navigation.value.filter(item => item.key !== 'my-bets')
        }

        function openLoginModal() {
            store.commit('OPEN_LOGIN_MODAL', true)
        }

        function openRegisterModal() {
            store.commit('OPEN_REGISTER_MODAL', true)
        }

        function onLogout() {
            store.dispatch(userActions.LOGOUT_USER)
        }

        function submitNavigationItem(navigationItem: Navigation) {
            const {path} = navigationItem;
            store.commit('SET_PATH', path)
            sendPostMessage('NAVIGATE_TO', path)
        }
        function openMySportsbookModal(){
            sendPostMessage('OPEN_MY_SPORTSBOOK_MODAL')
        }

      const ssoLink = ref('');
      async function buildSsoLink() {
          const publicKey = config.publicKey;
          const { username } = decodeJWT(store.state.user.jwt);

          if (publicKey && username) {
              const rawData = {
                username,
                timestamp: Math.floor(Date.now() / 1000),
              }
              const ssoHash = await RSAEncrypt(JSON.stringify(rawData), publicKey);
              ssoLink.value = `${config.orbitUrl}/?sb=${base64ToBase64Url(ssoHash)}`;
          } else {
            console.error('Unable to build SSO link');
          }
        }
        onMounted(buildSsoLink);

        return {
            navigation,
            isLogged,
            openLoginModal,
            openRegisterModal,
            onLogout,
            userBalance: computed(() => store.state.user.balance),
            submitNavigationItem,
            currentNavItem,
            config,
            openMySportsbookModal,
            registerEnabled,
            loginEnabled,
            ssoEnabled,
            ssoLink,
        }
    }
});
