const dk = {
    header: {
        home: 'Hjem',
        sports: 'Sport',
        live_betting: 'Live væddemål',
        login: 'Login',
        register: 'Register',
        balance: 'Balancen:',
    },
    profile_dropdown: {
        my_bets: 'Mine væddemål',
        my_sportsbook: 'Min sportsbook',
        log_out: 'Log ud',
    },
    login: {
        title: 'Log ind på din konto',
        username: 'Brugernavn',
        password: 'Adgangskode',
        sign_in: 'Log ind',
        error: {
            wrong_signatures: 'Forkert kombination af brugernavn og adgangskode!',
            something_went_wrong: 'Noget gik galt. Prøv venligst igen senere.',
        },
        remember_me: 'Husk mig',
        forgot_password: 'Har du glemt din adgangskode?',
    },
    register: {
        title: 'Opret en konto',
        email: 'E-mail',
        username: 'Brugernavn',
        first_name: 'Fornavn',
        last_name: 'Efternavn',
        password: 'Adgangskode',
        sign_up: 'Tilmeld dig',
        select_currency: 'Vælg valuta',
    },
    footer: {
        all_rights_reserved: 'Alle rettigheder forbeholdes.',
    }
}
export default dk
