
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { LockClosedIcon } from '@heroicons/vue/solid'
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {defineComponent} from "vue";
import FormInput from "@/components/FormInput.vue";
import userActions from '../store/user/user-actions'
import {XIcon} from "@heroicons/vue/outline";
import { siteConfig } from "@/composables/helpers";
import { SiteConfig, Currency } from "@/store/types/types";
import DropDown from "./DropDown.vue";

export default defineComponent({
    name: "register-modal",
    components: {
        DropDown,
		XIcon,
        FormInput,
        Dialog,
        DialogPanel,
        TransitionRoot,
        TransitionChild,
        LockClosedIcon,
    },
    setup() {
        const store = useStore();
        const email = ref('');
        const username = ref('');
        const firstname = ref('');
        const lastname = ref('');
        const password = ref('');
        const error = ref('');
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const currencies = siteConfig().currencies ?? [] as SiteConfig['currencies'];
        const currency = ref(null as Currency | null);

        function closeRegisterModal() {
            store.commit('OPEN_REGISTER_MODAL', false)
            clearRegisterData()
            error.value = '';
        }

        function onSubmitRegister(e: any) {
            e.preventDefault();
            error.value = '';
            if (!emailRegex.test(email.value)) {
                error.value = 'Invalid email address';
                return
            }
            if (currency.value === null) {
                error.value = 'Currency selection is required';
                return
            }
            store.dispatch(userActions.REGISTER_USER, {
                firstname: firstname.value,
                lastname: lastname.value,
                username: username.value,
                email: email.value,
                password: password.value,
                currency: currency?.value?.id,
                'user-locale': 'en_EN',
                'odds-format': 'decimal',
            }).then((response: {status: string, message: string}) => {
                if (response.status === 'error') {
                    error.value = response.message;
                } else {
                    store.dispatch(userActions.LOGIN_USER, {
                        username: username.value,
                        password: password.value,
                    })
                    closeRegisterModal()
                }
            })
        }

        function clearRegisterData() {
            email.value = ''
            username.value = ''
            firstname.value = ''
            lastname.value = ''
            password.value = ''
            currency.value = null
        }

        function selectCurrency(selectedCurrency: Currency) {
            currency.value = selectedCurrency;
        }

        function getCurrencyLabel(currency: Currency) {
            return `${currency.title} - ${currency.code}`;
        }

        return {
            isRegisterModalOpen: computed(() => store.state.application.openRegisterModal),
            closeRegisterModal,
            email,
            username,
            firstname,
            lastname,
            password,
            onSubmitRegister,
            clearRegisterData,
            error,
            selectCurrency,
            getCurrencyLabel,
            currencies,
            currency,
        }
    }
});
