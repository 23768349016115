const nl = {
    header: {
        home: 'Home',
        sports: 'Sport',
        live_betting: 'Live Wedden',
        login: 'Inloggen',
        register: 'Registreer',
        balance: 'Saldo:',
    },
    profile_dropdown: {
        my_bets: 'Mijn weddenschappen',
        my_sportsbook: 'Mijn sportwedkantoor',
        log_out: 'Afmelden',
    },
    login: {
        title: 'Aanmelden bij je account',
        username: 'Gebruikersnaam',
        password: 'Wachtwoord',
        sign_in: 'Aanmelden',
        error: {
            wrong_signatures: 'Verkeerde combinatie van gebruikersnaam en wachtwoord!',
            something_went_wrong: 'Er is iets misgegaan. Probeer het later nog eens.',
        },
        remember_me: 'Herinner mij',
        forgot_password: 'Wachtwoord vergeten?',
    },
    register: {
        title: 'Een account aanmaken',
        email: 'E-mail',
        username: 'Gebruikersnaam',
        first_name: 'Voornaam',
        last_name: 'Achternaam',
        password: 'Wachtwoord',
        sign_up: 'Aanmelden',
        select_currency: 'Selecteer valuta',
    },
    footer: {
        all_rights_reserved: 'Alle rechten voorbehouden.',
    }
}
export default nl
