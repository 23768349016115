import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "h-16 w-full dark:border-gray-800 bg-gray-800" }
const _hoisted_4 = { class: "h-full w-full container mx-auto px-4 lg:px-8 flex items-center justify-center" }
const _hoisted_5 = { class: "flex items-center text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_login_modal = _resolveComponent("login-modal")!
  const _component_register_modal = _resolveComponent("register-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_main_header, {
      onOpenLoginModal: _ctx.openLoginModal,
      onOpenRegisterModal: _ctx.openRegisterModal
    }, null, 8, ["onOpenLoginModal", "onOpenRegisterModal"]),
    _createElementVNode("div", {
      id: "main",
      class: _normalizeClass(["w-full", {'h-dynamic': _ctx.sportsbookDynamicHeight}])
    }, [
      _createElementVNode("iframe", {
        id: "sportsbookcontainer",
        src: _ctx.sportsBookUrl,
        "data-frame-id": "ds-sport-component",
        class: _normalizeClass(["w-full md:min-h-screen", [_ctx.sportsbookDynamicHeight ? 'h-dynamic' : 'h-full']]),
        onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onIframeLoad && _ctx.onIframeLoad(...args)))
      }, null, 42, _hoisted_2)
    ], 2),
    _createElementVNode("footer", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, " © " + _toDisplayString(_ctx.currentYear) + " Delasport. " + _toDisplayString(_ctx.$t("footer.all_rights_reserved")), 1)
      ])
    ]),
    _createVNode(_component_login_modal, { showLoginModal: _ctx.showLoginModal }, null, 8, ["showLoginModal"]),
    _createVNode(_component_register_modal, { showRegisterModal: _ctx.showRegisterModal }, null, 8, ["showRegisterModal"])
  ]))
}