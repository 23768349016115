import config from '../../config/config.json'
import userActions from "@/store/user/user-actions";
import store from "@/store";

type Message = {
    type?: string
    event?: string
    data?: {
        height?: number
    }
    payload?: any
}


export function siteConfig () {

    const isLogged = !!getCookie('sb');

    function getCookie(cname: string) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return "";
    }

    const sportsBookUrl = config.sportsBookUrl ?? ''
    const useParam = config.useParam ?? false
    const miniGames = config.miniGames ?? []
    const registerEnabled = config.registerEnabled ?? false
    const loginEnabled = config.loginEnabled ?? false
    const apiUrl = config.apiUrl ?? ''
    const googleAnalyticsTag = config.googleAnalyticsTag ?? ''
    const languagesList = config.languagesList ?? []
    const currencies = config.currencies ?? []
    const ssoEnabled = config.ssoEnabled ?? false
    const publicKey = config.publicKey ?? ''
    const orbitUrl = config.orbitUrl ?? ''
    const sportsbookDynamicHeight = config.sportsbookDynamicHeight ?? false

    return {
        isLogged,
        sportsBookUrl,
        apiUrl,
        useParam,
        miniGames,
        registerEnabled,
        loginEnabled,
        googleAnalyticsTag,
        languagesList,
        currencies,
        ssoEnabled,
        publicKey,
        orbitUrl,
        sportsbookDynamicHeight,
    }
}

export function getDomain() {
    const secondLevelDomains = ['.co.uk', '.co.net', '.co.nz', '.co.at', '.co.nl'];
    let domain = '.';

    const parts = window.location.hostname.split('.');
    const count = parts.length;

    if (count > 1) {
        const newParts = [parts[count - 2], parts[count - 1]];
        domain += newParts.join('.');

        if (count > 2 && secondLevelDomains.indexOf(domain) != -1) {
            domain = '.' + parts[count - 3] + domain;
        }
    }
    else {
        domain += window.location.hostname;
        console.log('Bad Domain Format: ' + domain);
    }

    return domain;
}

export const handlePostMessage = function (e: Message) {
    const messageData = typeof e.data === 'string' ? JSON.parse(e.data) : e.data

    switch (messageData.type) {
        case 'SET_ROUTE_AND_VIEW':
            store.commit('SET_PATH', messageData.payload.path)
            break
        case 'USER_LOGGED_IN':
            if (!messageData.payload) {
                store.dispatch(userActions.LOGOUT_USER)
            }
            break
        case 'SET_GAMIFICATION_POPUP_OPENED':
            if (messageData?.payload) {
                document.body.classList.add("missions-opened");
            }else {
                document.body.classList.remove("missions-opened");
            }
            break
        default:
            break
    }

    switch (messageData.event) {
        case 'update_height':
            updateIframeHeight(messageData.data.height)
            break
        default:
            break
    }
}

export const sendPostMessage = function (actionType: string, payload?: any) {
    const iframe = document.getElementById("sportsbookcontainer") as HTMLIFrameElement | null;
    if (!iframe) {
        console.error("Iframe not found");
        return;
    }
    let message;
    switch (actionType) {
        case 'NAVIGATE_TO':
            if (!payload) {
                console.error("There is no given path to navigate");
                return;
            }
            message = JSON.stringify({
                type: 'NAVIGATE_TO',
                payload: payload
            });
            break;
        case 'OPEN_MY_SPORTSBOOK_MODAL':
            message = JSON.stringify({
                type: 'OPEN_MY_SPORTSBOOK_MODAL',
            });
            break;
        default:
            console.error("Invalid action type");
            return;
    }
    iframe.contentWindow?.postMessage(message, '*');
};

export const arrayBufferToBase64 = (arrayBuffer: ArrayBuffer): string => {
    const byteArray = new Uint8Array(arrayBuffer);
    let byteString = '';

    for (let i= 0; i < byteArray.byteLength; i++) {
        byteString += String.fromCharCode(byteArray[i]);
    }

    return window.btoa(byteString); // base64
}

export const base64ToBase64Url = (base64String: string): string => {
    // Step 1: Decode the Base64 string to binary data
    const binaryString = atob(base64String);

    // Step 2: Convert binary data to a URL-safe Base64 string
    return btoa(binaryString)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}

export const base64StringToArrayBuffer = (b64str: string) => {
    const byteStr = atob(b64str);
    const bytes = new Uint8Array(byteStr.length);
    for (let i = 0; i < byteStr.length; i++) {
        bytes[i] = byteStr.charCodeAt(i);
    }

    return bytes.buffer;
}

export const convertPemToArrayBuffer = (pem: string): ArrayBuffer => {
    const lines = pem.split('\n');
    let encoded = '';

    for (let i = 0; i < lines.length; i++) {
        if (lines[i].trim().length > 0 &&
            lines[i].indexOf('-----BEGIN PUBLIC KEY-----') < 0 &&
            lines[i].indexOf('-----END PUBLIC KEY-----') < 0) {

            encoded += lines[i].trim();
        }
    }

    return base64StringToArrayBuffer(encoded);
}

export const RSAEncrypt = async (str: string, pemString: string): Promise<string> => {
    const encryptAlgorithm = {
        name: "RSA-OAEP",
        modulusLength: 3072,
        publicExponent: new Uint8Array([1, 0, 1]),
        extractable: true,
        hash: {
            name: "SHA-256"
        }
    }
    // convert string into ArrayBuffer
    const encodedPlaintext = new TextEncoder().encode(str).buffer;
    const keyArrayBuffer = convertPemToArrayBuffer(pemString);
    const secretKey = await crypto.subtle.importKey('spki', keyArrayBuffer, encryptAlgorithm, true, ['encrypt']);

    const encrypted = await crypto.subtle.encrypt({
        name: encryptAlgorithm.name,
    }, secretKey, encodedPlaintext);

    return arrayBufferToBase64(encrypted);
};

export const decodeJWT = (jwt: string) => {
    return JSON.parse(atob(jwt.split('.')[1]));
}

const updateIframeHeight = (value: number): void => {
    if (!config.sportsbookDynamicHeight) {
        return
    }

    document.getElementById('sportsbookcontainer')?.setAttribute('height', value.toFixed())
}