
import {defineComponent, ref} from "vue"
import {Language} from "@/store/types/types";
import {siteConfig} from "@/composables/helpers";
import i18n from "@/i18n.config";
export default defineComponent({
    name: "LanguageDropdown",
    setup() {
        const isDropdownOpen = ref(false);
        const selectedLanguage = ref('EN');
        function toggleLanguagesDropdown(){
            isDropdownOpen.value = !isDropdownOpen.value;
        }
        function handleLanguageClick(language: Language) {
            const frameContent = document.getElementById('sportsbookcontainer') as HTMLIFrameElement | null
            if (frameContent !== null) {
                frameContent.src = `${siteConfig().sportsBookUrl}${language.path}`
            }
            isDropdownOpen.value = false;
            const lowerCaseLanguage = language.path.slice(1);
            selectedLanguage.value = language.path.slice(1).toUpperCase();
            i18n.global.locale = lowerCaseLanguage;
        }

        return {
            languagesList: siteConfig().languagesList,
            toggleLanguagesDropdown,
            handleLanguageClick,
            isDropdownOpen,
            selectedLanguage,
        }
    }
})
