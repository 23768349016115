import {Module} from "vuex";
import {RootState, SiteConfig} from "@/store/types/types";
import {UserState} from "@/store/user/types";
import {getters} from "@/store/user/getters";
import {mutations} from "@/store/user/mutations";
import {actions} from "@/store/user/actions";
import {siteConfig} from "@/composables/helpers";

const config: SiteConfig = siteConfig();

const state: UserState = {
    balance: '',
    sbToken: localStorage.getItem("sbToken") ?? '',
    isLogged: config.isLogged,
    jwt: localStorage.getItem("sbJWT") ?? '',
}

export const user: Module<UserState, RootState> = {
    state,
    getters,
    mutations,
    actions,
}